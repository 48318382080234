export const greetingTxt = lang => {
  if (lang === 'en') return 'Ask tarot reader anything you want to know.';
  if (lang === 'ja') return 'タロットの読者に何を知りたいのか質問してください。';
  if (lang === 'es') return 'Pregunta al tarotista cualquier cosa que quieras saber.';
  return 'Ask tarot reader anything you want to know.';
};
export const returnCtaTxt = lang => {
  if (lang === 'en') return 'Submit';
  if (lang === 'ja') return '提出';
  if (lang === 'es') return 'Enviar';
  return 'Submit';
};

export const loadingTxt = lang => {
  if (lang === 'en') return 'Tarot reading in progress...(takes up to 1 minute)';
  if (lang === 'ja') return 'タロット読書中...（最大1分かかります）';
  if (lang === 'es') return 'Lectura del tarot en curso...(toma hasta 1 minuto)';
  return 'Tarot reading in progress...(takes up to 1 minute)';
};

export const indicateTxt = lang => {
  if (lang === 'en') return 'It may take more than one minute depending on the input.';
  if (lang === 'ja') return '入力によっては1分以上かかる可能性があります。';
  if (lang === 'es') return 'Puede tomar más de un minuto dependiendo de la entrada.';
  return 'It may take more than one minute depending on the input.';
};

export const lengthIndicateTxt = lang => {
  if (lang === 'en') return 'Please enter more than 15 characters.';
  if (lang === 'es') return 'Ingrese al menos 15 caracteres.';
  if (lang === 'ja') return '15文字以上入力してください。';
  return 'Please enter more than 15 characters.';
};

export const finalTxt = lang => {
  if (lang === 'en') return 'Result of tarot reading';
  if (lang === 'es') return 'Resultado de la lectura del tarot';
  if (lang === 'ja') return 'タロットリーディングの結果';
  return 'Result of tarot reading';
};

export const ratingTxt = lang => {
  if (lang === 'en') return 'How was the tarot reading?';
  if (lang === 'es') return '¿Cómo fue la lectura del tarot?';
  if (lang === 'ja') return 'タロットリーディングはどうだった？';
  return 'How was the tarot reading?';
};

export const thankYouTxt = lang => {
  if (lang === 'en') return 'Thank you.';
  if (lang === 'es') return 'Gracias';
  if (lang === 'ja') return 'ありがとう';
  return 'Thank you';
};

export const ratingQuestionTxt = (lang, rating) => {
  if (lang === 'en') return `What is the reason for giving ${rating}? (optional)`;
  if (lang === 'es') return `¿Cuál es la razón para dar ${rating}? (opcional)`;
  if (lang === 'ja') return `${rating} (任意) を与える理由は何ですか？`;
  return `What is the reason for giving ${rating}? (optional)`;
};

export const submittingTxt = lang => {
  if (lang === 'en') return 'Submitting';
  if (lang === 'es') return 'Enviando...';
  if (lang === 'ja') return '提出する...';
  return 'Submitting';
};

export const shareCtaTxt = lang => {
  if (lang === 'en') return 'Share';
  if (lang === 'es') return 'Compartir';
  if (lang === 'ja') return '共有';
  return 'Share';
};

export const installCtaTxt = lang => {
  if (lang === 'en') return 'Install App';
  if (lang === 'es') return 'Instalar la aplicación.';
  if (lang === 'ja') return 'アプリをインストールする';
  return 'Install App';
};

export const installIndicateTxt = lang => {
  // If you want to get free tarot readings every day, install the app.
  if (lang === 'en') return 'Install the app for every day 1:1 free tarot.';
  if (lang === 'es') return 'Instala la aplicación para tarot gratuito 1:1 todos los días.';
  if (lang === 'ja') return 'アプリをインストールして、毎日1対1のフリータロットを楽しもう。';
  return 'Install the app for every day 1:1 free tarot.';
};

export const copiedIndicateTxt = lang => {
  if (lang === 'en') return 'Copied Link! Share this to your friends';
  if (lang === 'es') return '¡Enlace copiado! Comparte esto con tus amigos';
  if (lang === 'ja') return 'リンクをコピーしました！友達に共有してください。';
  return 'Copied Link! Share this to your friends';
};
