import styled from 'styled-components';
import './loadAmplitude';
import { useState, useEffect } from 'react';
import amplitude from 'amplitude-js';
import { v4 as uuidv4 } from 'uuid';
import devider from './assets/border.png';
import './App.css';
import InputWorryPage from './pages/InputWorryPage';
import Ready from './pages/Ready';
import { fetchUtmMap, UuidKey } from './utils/datautils';
import { createUser } from './api/logging';

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const [isError, setIsError] = useState(false);
  const [uuid, setUuid] = useState('');

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    let uuid;

    if (localStorage.getItem(UuidKey)) {
      uuid = localStorage.getItem(UuidKey);
    } else {
      uuid = uuidv4() || new Date().getTime();
      localStorage.setItem(UuidKey, uuid);
    }

    setUuid(uuid);

    const referral = urlParams.get('referral') ?? '';
    const utmMap = fetchUtmMap();
    const lang = urlParams.get('lang') ?? 'en';
    createUser(uuid, referral, utmMap, lang);
  }, []);
  const [pageNum, setPageNum] = useState(0);
  const buttonClickHandler = () => {
    setPageNum(prev => prev + 1);
  };

  const [inputWorry, setInputWorry] = useState('');
  const [answer, setAnswer] = useState('');

  const inputWorryChangeHandler = event => {
    setInputWorry(prev => event.target.value);
  };

  return (
    <>
      <div
        style={{
          position: 'relative',
          padding: '0 2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Devider src={devider} style={{ marginTop: '4rem' }} />

        {pageNum === 0 && (
          <InputWorryPage
            uuid={uuid}
            value={inputWorry}
            onChange={inputWorryChangeHandler}
            onReady={buttonClickHandler}
            setAnswer={setAnswer}
          />
        )}
        {pageNum === 1 && <Ready answer={answer} uuid={uuid} />}
      </div>
    </>
  );
}

export default App;
