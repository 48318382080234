import { useEffect, useState } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-hot-toast';
import Text from '../components/Text';
import Button from '../components/Button';
import { supabase } from '../utils/supabase';
import {
  finalTxt,
  ratingQuestionTxt,
  ratingTxt,
  returnCtaTxt,
  submittingTxt,
  thankYouTxt,
  shareCtaTxt,
  installCtaTxt,
  copiedIndicateTxt,
  installIndicateTxt,
} from '../utils/lang';

import { loggingUserAction } from '../api/logging';
import { USER_ACTION } from '../constants';

const Ready = ({ answer, uuid }) => {
  const [rating, setRating] = useState(0);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_result', { referral: 'tarot_ai_global', lang: lang ?? 'en' });
  }, []);

  const utmParams = new URLSearchParams(window.location.search);
  const lang = utmParams.get('lang') ?? '';

  const feedbackInputChangeHandler = event => {
    setFeedback(prev => event.target.value);
  };

  const shareBtnClickHandler = () => {
    window.navigator.clipboard
      .writeText(`https://daily.tarotsister.co/?referral=${uuid}&utm_source=referral&lang=${lang}`)
      .then(() => alert(copiedIndicateTxt(lang)));
  };

  const onClickSubmit = async () => {
    try {
      setIsLoading(prev => true);
      if (uuid) {
        const { data, error } = await supabase
          .from('ai_tarot')
          .select('id')
          .eq('key', uuid)
          .order('id', { ascending: false })
          .limit(1)
          .single();

        if (error) {
          return;
        }

        if (data.id) {
          const { data: dataUpdated, error } = await supabase
            .from('ai_tarot')
            .update({ rating, feedback })
            .eq('id', data.id)
            .select('*');

          if (error) return;
        }
        amplitude.getInstance().logEvent('submit_feedback', { referral: 'tarot_ai_global', lang: lang ?? 'en' });
      }

      setIsSubmited(true);
    } catch (e) {
      toast.error('Error while rating');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const ratingChangeHandler = newRating => {
    setRating(newRating);
  };

  return !isSubmited ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '2.5rem', textAlign: 'center' }}>
        {finalTxt(lang)}
      </SubText>
      <SubText style={{ marginBottom: '3rem' }}>
        <br />
        {answer.trim()}
        <br />
      </SubText>
      <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '1.6rem', textAlign: 'center' }}>
        {installIndicateTxt(lang)}
        <br />
      </SubText>
      <div style={{ marginTop: '1.6rem', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Button
          text={shareCtaTxt(lang)}
          width="49%"
          color="#000"
          backgroundColor={'#fff'}
          style={{
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: '6.4rem',
            marginTop: '1.6rem',
            marginBottom: '5rem',
            flexShrink: 0,
          }}
          border={'1px solid #000'}
          onClickHandler={() => {
            loggingUserAction(uuid, USER_ACTION.share);
            amplitude.getInstance().logEvent('click_share', { referral: 'tarot_ai_global', lang: lang ?? 'en' });
            shareBtnClickHandler();
          }}
        />
        <Button
          text={installCtaTxt(lang)}
          width="49%"
          color="#fff"
          backgroundColor={'#E1354C'}
          style={{
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: '6.4rem',
            marginTop: '1.6rem',
            marginBottom: '5rem',
            flexShrink: 0,
          }}
          onClickHandler={() => {
            loggingUserAction(uuid, USER_ACTION.install);
            amplitude.getInstance().logEvent('click_install', { referral: 'tarot_ai_global', lang: lang ?? 'en' });
            alert("We're preparing for the app.");
          }}
        />
      </div>
      <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '1.6rem', textAlign: 'center' }}>
        {ratingTxt(lang)}
        <br />
      </SubText>
      <div style={{ marginTop: '1.6rem', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <StarRatings
          rating={rating}
          changeRating={ratingChangeHandler}
          starRatedColor={'rgb(248,212,86)'}
          starHoverColor={'rgb(248,212,86)'}
        />
      </div>
      {rating > 0 && (
        <>
          <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '1.6rem', textAlign: 'center' }}>
            {ratingQuestionTxt(lang, rating)}
          </SubText>
          <Input placeholder="" value={feedback} onChange={feedbackInputChangeHandler} />
        </>
      )}
      {isLoading && (
        <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '1.6rem', textAlign: 'center' }}>
          {submittingTxt(lang)}
        </SubText>
      )}

      <Button
        text={returnCtaTxt(lang)}
        width="100%"
        color="#fff"
        backgroundColor={'#222529'}
        style={{
          fontFamily: 'Roboto',
          fontSize: '2rem',
          fontWeight: 700,
          lineHeight: '6.4rem',
          marginTop: '1.6rem',
          marginBottom: '5rem',
        }}
        onClickHandler={
          rating === 0
            ? () => {
                toast.error('Please give us feedback first.');
              }
            : onClickSubmit
        }
      />
    </div>
  ) : (
    <Text style={{ marginTop: '5rem' }}>{thankYouTxt(lang)}</Text>
  );
};

export const SubText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 180%;
  white-space: pre-wrap;
  color: #2e1717;
`;

export default Ready;

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  background: none;
  letter-spacing: 0.15px;
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;
