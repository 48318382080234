import { useEffect, useState } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import { toast } from 'react-hot-toast';
import { TypeAnimation } from 'react-type-animation';
import Button from '../components/Button';
import Text from '../components/Text';
import { askAITarot } from '../api';
import { supabase } from '../utils/supabase';
import { fetchUtmMap } from '../utils/datautils';
import { SubText } from './Ready';
import { returnCtaTxt, loadingTxt, indicateTxt, lengthIndicateTxt, greetingTxt } from '../utils/lang';

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  background: none;
  letter-spacing: 0.15px;
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;

const InputWorryPage = ({ value, onChange, onReady, setAnswer, uuid }) => {
  const [registered, setRegistsered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const utmParams = new URLSearchParams(window.location.search);
  const lang = utmParams.get('lang') ?? 'en';

  const userRegisterHandler = async () => {
    amplitude.getInstance().logEvent('submit_worry', { referral: 'tarot_ai_global', lang });

    if (registered) return;

    try {
      setIsLoading(prev => true);

      const askedResponse = await askAITarot(value, lang);

      setAnswer(askedResponse.data.choices[0].text);
      setIsLoading(prev => false);
      onReady();

      const { data: userData, error: userDataError } = await supabase
        .from('ai_tarot')
        .update({
          input: value,
          answer: askedResponse.data.choices[0].text,
        })
        .eq('key', uuid)
        .order('id', { ascending: false })
        .limit(1)
        .select('*');
      setRegistsered(true);
    } catch (error) {
      toast.error('Please prompt it shorter.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_main', { referral: 'tarot_ai_global', lang: lang ?? 'en' });
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <TypeAnimation
            style={{
              marginTop: '8rem',
              fontFamily: 'OK GUNG',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '2.4rem',
              lineHeight: '180%',
              color: '#2e1717',
            }}
            sequence={[`${loadingTxt(lang)}`]}
          />
          <SubText style={{ marginTop: '1.6rem', textAlign: 'center' }}>{indicateTxt(lang)}</SubText>
        </>
      ) : (
        <>
          <Text style={{ width: '100%', margin: '1.6rem 0' }}>
            {greetingTxt(lang)}
            <br />
            {value.length === 0 && <span style={{ color: '#E1354C' }}>{lengthIndicateTxt(lang)}</span>}
            {value.length <= 15 && value.length !== 0 && value.length !== 15 && (
              <span style={{ color: '#E1354C' }}>{`${15 - value.length} ${
                lang === 'en' || lang === 'es' ? 'characters more' : '字以上を入力してください。'
              }.`}</span>
            )}
          </Text>
          <Input placeholder={''} value={value} onChange={onChange} />
          <div
            style={{
              marginTop: '1.6rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <Button
              text={returnCtaTxt(lang)}
              width="50%"
              color="#fff"
              backgroundColor={value.length >= 15 ? '#222529' : '#CFD4DA'}
              style={{
                fontFamily: 'Roboto',
                fontSize: '2rem',
                fontWeight: 700,
                lineHeight: '6.4rem',
              }}
              onClickHandler={
                value.length >= 15
                  ? userRegisterHandler
                  : () => {
                      toast.error('Please enter more than 15 characters.');
                    }
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default InputWorryPage;
