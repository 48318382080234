export const IS_TEST = false;
export const counselor = '미나';
export const REGISTER_STATE = {
  init: 'INIT',
  loading: 'LOADING',
  complete: 'COMPLETE',
  duplicated: 'DUPLICATED',
  error: 'ERROR',
};

export const USER_ACTION = {
  install: 'install',
  share: 'share',
};
