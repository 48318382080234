import { openai } from '../utils/openai';

export const askAITarot = (worry, lang) => {
  if (lang === 'en') {
    return openai.createCompletion({
      model: 'text-davinci-003',
      prompt: `You are a tarot master and a customer asks you for a tarot reading for this: "${worry}" Draw some cards and give a tarot reading for this spirit.`,
      max_tokens: 2048,
      temperature: 0.7,
    });
  }

  if (lang === 'ja') {
    return openai.createCompletion({
      model: 'text-davinci-003',
      prompt: `You are a tarot master and a customer asks you for a tarot reading for this: "${worry}"\nDraw some cards and give a tarot reading in Japanese.`,
      max_tokens: 1024,
      temperature: 0.7,
    });
  }

  if (lang === 'es') {
    return openai.createCompletion({
      model: 'text-davinci-003',
      prompt: `You are a tarot master and a customer asks you for a tarot reading for this: "${worry}"\nDraw some cards and give a tarot reading in Spanish.`,
      max_tokens: 1024,
      temperature: 0.7,
    });
  }

  return openai.createCompletion({
    model: 'text-davinci-003',
    prompt: `You are a tarot master and a customer asks you for a tarot reading for this: "${worry}" Draw some cards and give a tarot reading for this spirit.`,
    max_tokens: 2048,
    temperature: 0.7,
  });
};

export const translateToEnglish = worry =>
  openai.createCompletion({
    model: 'text-davinci-003',
    prompt: `${worry}\nTranslate upper sentence into English.`,
    max_tokens: 2048,
    temperature: 0,
  });

export const translateToKorean = worry =>
  openai.createCompletion({
    model: 'text-davinci-003',
    prompt: `"${worry}"\n위 내용을 맘씨 좋은 할머니 말투로 번역해줘. 할머니라는 건 언급하지 않아도 돼. 번역투가 아니어야 해. 아래 예시들을 참고하도록 해.\nPentacles: "펜타클", Cups: "컵", Swords: "소드", Wands: "완드", Three of: "3", Six of: "6", Ace of: "에이스", Knight of: "나이트", Closure: "끝", older brother: "오빠"\n`,
    max_tokens: 2048,
    temperature: 0,
  });
