import { supabase } from '../utils/supabase';
import { IS_TEST, USER_ACTION } from '../constants';

export const createUser = async (uuid, referralUuid, utmMap, lang) => {
  try {
    const { data, error } = await supabase.from('ai_tarot').insert({
      key: uuid,
      referral_uuid: referralUuid,
      is_test: IS_TEST,
      lang,
      ...utmMap,
    });

    if (data) return true;
    throw new Error(`${error}`);
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const loggingUserAction = async (uuid, action) => {
  const userAction = {};

  if (action === USER_ACTION.share) {
    userAction.click_share = true;
  }
  if (action === USER_ACTION.install) {
    userAction.click_install = true;
  }

  try {
    const { data, error } = await supabase
      .from('ai_tarot')
      .update({ ...userAction })
      .eq('key', uuid)
      .not('input', 'is', null)
      .order('id', { ascending: false })
      .limit(1);

    if (data) return true;
    throw new Error(`${error}`);
  } catch (e) {
    console.log(e);
  }
};
